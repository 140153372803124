export const DUMMY_TAGS = [
  "Fiction",
  "Survivor",
  "Nonfiction",
  "Mystery",
  "Poetry",
  "Contemporary",
  "Feminist",
  "Brave Protag",
  "Poetry",
  "Magic",
  "Young Adult",
  "Magic",
  "Survivor",
  "Nonfiction",
  "Mystery",
]

export const TEMP_PROFILE = {
  id: "temp",
  username: "samantha",
  firstName: "Samantha",
  lastName: "Nelson",
  gender: "female",
  image: "/assets/temp/user/profile.jpg",
  cover: "/assets/temp/user/cover.jpg",
  age: 24,
  country: "England",
  totalRatings: 1927,
  totalReviews: 2182,
  booksRead: 880,
  totalLists: 120,
}

export const DEMO_BOOK = {
  id: "08a1417f-72ae-480e-8f82-3903d52ac78a",
  cover: "/assets/review-book-cover.png",
  slug: "indispensable-right",
  title: "The Indispensable Right: Free Speech in an Age of Rage",
  authors: [
    {
      name: "Jonathan Turley",
      id: "a4093557-bc1b-4412-974b-1f0bbb78605a",
      slug: "j-k-rowling",
    },
  ],
  rating: 7.9,
  totalRatings: 2123,
  totalReviews: 1221,
  pages: 500,
  isbn: 1635575567,
  editions: 3112,
  published: "5 June 2024",
  language: "English",
  format: "Paperback",
  description: `In an era marked by increasing polarization and widespread social upheaval, "The Indispensable Right: Free Speech in an Age of Rage" delves into the crucial role of free speech in maintaining a democratic society. This compelling book explores the historical foundations of free speech, its legal protections, and its vital importance in the face of contemporary challenges. From the rise of social media to the spread of misinformation and the fervor of cancel culture, the author navigates the complexities and controversies surrounding free speech today.
    \nThrough thought-provoking analysis and real-world examples, this book underscores the necessity of protecting this fundamental right while fostering respectful and constructive dialogue in a time of heightened emotions and conflict.
    \nThrough thought-provoking analysis and real-world examples, this book underscores the necessity of protecting this fundamental right while fostering respectful and constructive dialogue in a time of heightened emotions and conflict.`,
  // tags: [
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   { tag: "Music", likes: 390, category: "popular", categoryLabel: "Popular" },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   { tag: "Music", likes: 390, category: "popular", categoryLabel: "Popular" },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   { tag: "Music", likes: 390, category: "popular", categoryLabel: "Popular" },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "popular",
  //     categoryLabel: "Popular",
  //   },

  //   {
  //     tag: "NonFiction",
  //     likes: 501,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Mystery",
  //     likes: 488,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "contentWarning",
  //     categoryLabel: "Content Warning",
  //   },

  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Thriller",
  //     likes: 100,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Fiction",
  //     likes: 501,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Survivor",
  //     likes: 488,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Music",
  //     likes: 390,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Horror",
  //     likes: 300,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  //   {
  //     tag: "Nonfiction",
  //     likes: 200,
  //     category: "sexualContent",
  //     categoryLabel: "Sexual Content",
  //   },
  // ],

  reviews: [
    {
      id: 1,
      author: "Bookworm99",
      type: "standard",
      published: "1hr ago",
      content: `A Court of Mist and Fury" by Sarah J. Maas is a masterpiece that left me absolutely spellbound. Picking up from the events of "A Court of Thorns and Roses," this book delves deep into Feyre's emotional and psychological journey after her traumatic experiences Under the Mountain. Maas portrays Feyre's struggle with PTSD so realistically that it feels deeply personal and moving.
\nThe character development is phenomenal, especially with Rhysand, the mysterious High Lord of the Night Court. His interactions with Feyre are filled with tension and unspoken emotions, making their slow-burn romance incredibly captivating. The new characters—Mor, Cassian, and Azriel—bring so much life and depth to the story.`,
      likes: 456,
      rating: 7,
      replies: [
        {
          id: 1,
          author: "SarahJMaas",
          // type: 'standard',
          published: "1hr ago",
          content: `Thank you so much for your wonderful review! I'm thrilled to hear that Feyre's journey and the Night Court resonated with you. Writing about her emotional struggles and the romance with Rhysand was deeply personal, and it means so much to know it touched you. Your support and enthusiasm truly inspire me!`,
          likes: 456,
        },
        {
          id: 2,
          author: "StorySeeker45",
          // type: 'standard',
          published: "1hr ago",
          content: `So happy you loved the book! Maas’s ability to create such a vivid world and complex characters is truly remarkable. Thanks for highlighting the romance and character depth! `,
          likes: 456,
        },
        {
          id: 3,
          author: "Nightreader111",
          // type: 'standard',
          published: "1hr ago",
          content: `@StorySeeker45 I agree, Maas’s world-building and character complexity are fantastic. The romance and depth really add to the story's richness. Glad we share the same appreciation for the book!`,
          likes: 456,
        },
      ],
    },

    {
      id: 2,
      author: "SandyKidman",
      type: "critique",
      published: "1hr ago",
      content: `A Court of Mist and Fury" by Sarah J. Maas is a masterpiece that left me absolutely spellbound. Picking up from the events of "A Court of Thorns and Roses," this book delves deep into Feyre's emotional and psychological journey after her traumatic experiences Under the Mountain. Maas portrays Feyre's struggle with PTSD so realistically that it feels deeply personal and moving.
\nThe character development is phenomenal, especially with Rhysand, the mysterious High Lord of the Night Court. His interactions with Feyre are filled with tension and unspoken emotions, making their slow-burn romance incredibly captivating. The new characters—Mor, Cassian, and Azriel—bring so much life and depth to the story.`,
      likes: 456,
      rating: 7,
      replies: [
        {
          id: 1,
          author: "SarahJMaas",
          // type: 'standard',
          published: "1hr ago",
          content: `Thank you so much for your wonderful review! I'm thrilled to hear that Feyre's journey and the Night Court resonated with you. Writing about her emotional struggles and the romance with Rhysand was deeply personal, and it means so much to know it touched you. Your support and enthusiasm truly inspire me!`,
          likes: 456,
        },
        {
          id: 2,
          author: "StorySeeker45",
          // type: 'standard',
          published: "1hr ago",
          content: `So happy you loved the book! Maas’s ability to create such a vivid world and complex characters is truly remarkable. Thanks for highlighting the romance and character depth! `,
          likes: 456,
        },
        {
          id: 3,
          author: "Nightreader111",
          // type: 'standard',
          published: "1hr ago",
          content: `@StorySeeker45 I agree, Maas’s world-building and character complexity are fantastic. The romance and depth really add to the story's richness. Glad we share the same appreciation for the book!`,
          likes: 456,
        },
      ],
    },
    {
      id: 3,
      author: "BookLover",
      type: "funny",
      published: "1hr ago",
      rating: 7,
      content: `A Court of Mist and Fury: where Feyre’s emotional rollercoaster makes yours look like a kiddie ride, and Rhysand's charm could make even a stone statue swoon. 8/10 for epic drama!`,
      likes: 456,
      replies: [
        {
          id: 1,
          author: "SarahJMaas",
          // type: 'standard',
          published: "1hr ago",
          content: `Thank you so much for your wonderful review! I'm thrilled to hear that Feyre's journey and the Night Court resonated with you. Writing about her emotional struggles and the romance with Rhysand was deeply personal, and it means so much to know it touched you. Your support and enthusiasm truly inspire me!`,
          likes: 456,
        },
        {
          id: 2,
          author: "StorySeeker45",
          // type: 'standard',
          published: "1hr ago",
          content: `So happy you loved the book! Maas’s ability to create such a vivid world and complex characters is truly remarkable. Thanks for highlighting the romance and character depth! `,
          likes: 456,
        },
        {
          id: 3,
          author: "Nightreader111",
          // type: 'standard',
          published: "1hr ago",
          content: `@StorySeeker45 I agree, Maas’s world-building and character complexity are fantastic. The romance and depth really add to the story's richness. Glad we share the same appreciation for the book!`,
          likes: 456,
        },
      ],
    },
  ],
}

export const JUST_RELEASED = {
  books: [
    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-just-released-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "to-read",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "to-read",
      author: "Tara Westover",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "to-read",
      author: "Michelle Obama",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "to-read",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "to-read",
      author: "David McCullough",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "to-read",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "to-read",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "to-read",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "to-read",
      author: "Erik Larson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "to-read",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "to-read",
      author: "Trevor Noah",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "to-read",
      author: "J.D. Vance",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "to-read",
      author: "Susan Cain",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "to-read",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "to-read",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "to-read",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "to-read",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "to-read",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "to-read",
      author: "Jared Diamond",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "to-read",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "to-read",
      author: "John Carreyrou",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "to-read",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "to-read",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "to-read",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "to-read",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "to-read",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "to-read",
      author: "Tina Fey",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "to-read",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "must-read",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "must-read",
      author: "Harper Lee",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "must-read",
      author: "George Orwell",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "must-read",
      author: "Jane Austen",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "must-read",
      author: "Herman Melville",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "must-read",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "must-read",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "must-read",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "must-read",
      author: "James Joyce",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "must-read",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "must-read",
      author: "Emily Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "must-read",
      author: "Charles Dickens",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "must-read",
      author: "Joseph Heller",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "must-read",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "must-read",
      author: "Mary Shelley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "must-read",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "must-read",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "must-read",
      author: "Homer",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "must-read",
      author: "Victor Hugo",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "must-read",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "must-read",
      author: "John Steinbeck",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "must-read",
      author: "Bram Stoker",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "must-read",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "must-read",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "must-read",
      author: "William Golding",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "must-read",
      author: "Toni Morrison",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "must-read",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "must-read",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-just-released-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "fantasy",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "fantasy",
      author: "Tara Westover",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "fantasy",
      author: "Michelle Obama",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "fantasy",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "fantasy",
      author: "David McCullough",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "fantasy",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "fantasy",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "fantasy",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "fantasy",
      author: "Erik Larson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "fantasy",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "fantasy",
      author: "Trevor Noah",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "fantasy",
      author: "J.D. Vance",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "fantasy",
      author: "Susan Cain",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "fantasy",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "fantasy",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "fantasy",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "fantasy",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "fantasy",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "fantasy",
      author: "Jared Diamond",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "fantasy",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "fantasy",
      author: "John Carreyrou",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "fantasy",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "fantasy",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "fantasy",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "fantasy",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "fantasy",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "fantasy",
      author: "Tina Fey",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "fantasy",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "sci-fi",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "sci-fi",
      author: "Harper Lee",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "sci-fi",
      author: "George Orwell",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "sci-fi",
      author: "Jane Austen",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "sci-fi",
      author: "Herman Melville",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "sci-fi",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "sci-fi",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "sci-fi",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "sci-fi",
      author: "James Joyce",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "sci-fi",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "sci-fi",
      author: "Emily Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "sci-fi",
      author: "Charles Dickens",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "sci-fi",
      author: "Joseph Heller",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "sci-fi",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "sci-fi",
      author: "Mary Shelley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "sci-fi",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "sci-fi",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "sci-fi",
      author: "Homer",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "sci-fi",
      author: "Victor Hugo",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "sci-fi",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "sci-fi",
      author: "John Steinbeck",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "sci-fi",
      author: "Bram Stoker",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "sci-fi",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "sci-fi",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "sci-fi",
      author: "William Golding",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "sci-fi",
      author: "Toni Morrison",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "sci-fi",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "sci-fi",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-just-released-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "historical",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "historical",
      author: "Tara Westover",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "historical",
      author: "Michelle Obama",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "historical",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "historical",
      author: "David McCullough",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "historical",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "historical",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "historical",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "historical",
      author: "Erik Larson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "historical",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "historical",
      author: "Trevor Noah",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "historical",
      author: "J.D. Vance",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "historical",
      author: "Susan Cain",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "historical",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "historical",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "historical",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "historical",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "historical",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "historical",
      author: "Jared Diamond",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "historical",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "historical",
      author: "John Carreyrou",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "historical",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "historical",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "historical",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "historical",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "historical",
      author: "Michael Lewis",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "historical",
      author: "Tina Fey",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "historical",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-just-released-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "biography",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "biography",
      author: "Harper Lee",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "biography",
      author: "George Orwell",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "biography",
      author: "Jane Austen",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "biography",
      author: "Herman Melville",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "biography",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "biography",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "biography",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "biography",
      author: "James Joyce",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "biography",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "biography",
      author: "Emily Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "biography",
      author: "Charles Dickens",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "biography",
      author: "Joseph Heller",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "biography",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "biography",
      author: "Mary Shelley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "biography",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "biography",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "biography",
      author: "Homer",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "biography",
      author: "Victor Hugo",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "biography",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "biography",
      author: "John Steinbeck",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "biography",
      author: "Bram Stoker",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "biography",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "biography",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "biography",
      author: "William Golding",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "biography",
      author: "Toni Morrison",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "biography",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "biography",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-just-released-fiction.png",
    },
  ],
  url: "#",
}

export const COMING_SOON = {
  books: [
    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "to-read",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "to-read",
      author: "Tara Westover",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "to-read",
      author: "Michelle Obama",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "to-read",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "to-read",
      author: "David McCullough",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "to-read",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "to-read",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "to-read",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "to-read",
      author: "Erik Larson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "to-read",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "to-read",
      author: "Trevor Noah",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "to-read",
      author: "J.D. Vance",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "to-read",
      author: "Susan Cain",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "to-read",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "to-read",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "to-read",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "to-read",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "to-read",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "to-read",
      author: "Jared Diamond",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "to-read",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "to-read",
      author: "John Carreyrou",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "to-read",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "to-read",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "to-read",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "to-read",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "to-read",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "to-read",
      author: "Tina Fey",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "to-read",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "must-read",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "must-read",
      author: "Harper Lee",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "must-read",
      author: "George Orwell",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "must-read",
      author: "Jane Austen",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "must-read",
      author: "Herman Melville",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "must-read",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "must-read",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "must-read",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "must-read",
      author: "James Joyce",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "must-read",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "must-read",
      author: "Emily Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "must-read",
      author: "Charles Dickens",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "must-read",
      author: "Joseph Heller",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "must-read",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "must-read",
      author: "Mary Shelley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "must-read",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "must-read",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "must-read",
      author: "Homer",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "must-read",
      author: "Victor Hugo",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "must-read",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "must-read",
      author: "John Steinbeck",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "must-read",
      author: "Bram Stoker",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "must-read",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "must-read",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "must-read",
      author: "William Golding",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "must-read",
      author: "Toni Morrison",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "must-read",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "must-read",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "fantasy",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "fantasy",
      author: "Tara Westover",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "fantasy",
      author: "Michelle Obama",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "fantasy",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "fantasy",
      author: "David McCullough",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "fantasy",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "fantasy",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "fantasy",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "fantasy",
      author: "Erik Larson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "fantasy",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "fantasy",
      author: "Trevor Noah",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "fantasy",
      author: "J.D. Vance",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "fantasy",
      author: "Susan Cain",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "fantasy",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "fantasy",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "fantasy",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "fantasy",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "fantasy",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "fantasy",
      author: "Jared Diamond",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "fantasy",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "fantasy",
      author: "John Carreyrou",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "fantasy",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "fantasy",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "fantasy",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "fantasy",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "fantasy",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "fantasy",
      author: "Tina Fey",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "fantasy",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "sci-fi",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "sci-fi",
      author: "Harper Lee",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "sci-fi",
      author: "George Orwell",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "sci-fi",
      author: "Jane Austen",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "sci-fi",
      author: "Herman Melville",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "sci-fi",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "sci-fi",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "sci-fi",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "sci-fi",
      author: "James Joyce",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "sci-fi",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "sci-fi",
      author: "Emily Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "sci-fi",
      author: "Charles Dickens",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "sci-fi",
      author: "Joseph Heller",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "sci-fi",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "sci-fi",
      author: "Mary Shelley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "sci-fi",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "sci-fi",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "sci-fi",
      author: "Homer",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "sci-fi",
      author: "Victor Hugo",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "sci-fi",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "sci-fi",
      author: "John Steinbeck",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "sci-fi",
      author: "Bram Stoker",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "sci-fi",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "sci-fi",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "sci-fi",
      author: "William Golding",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "sci-fi",
      author: "Toni Morrison",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "sci-fi",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "sci-fi",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },

    {
      id: "jr-1",
      title: "Sapiens",
      url: "https://example.com/jr-sapiens",
      category: "historical",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-2",
      title: "Educated",
      url: "https://example.com/jr-educated",
      category: "historical",
      author: "Tara Westover",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-3",
      title: "Becoming",
      url: "https://example.com/jr-becoming",
      category: "historical",
      author: "Michelle Obama",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/jr-the-immortal-life-of-henrietta-lacks",
      category: "historical",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-5",
      title: "The Wright Brothers",
      url: "https://example.com/jr-the-wright-brothers",
      category: "historical",
      author: "David McCullough",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-6",
      title: "Into the Wild",
      url: "https://example.com/jr-into-the-wild",
      category: "historical",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-7",
      title: "The Glass Castle",
      url: "https://example.com/jr-the-glass-castle",
      category: "historical",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-8",
      title: "Unbroken",
      url: "https://example.com/jr-unbroken",
      category: "historical",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-9",
      title: "The Devil in the White City",
      url: "https://example.com/jr-the-devil-in-the-white-city",
      category: "historical",
      author: "Erik Larson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/jr-the-man-who-mistook-his-wife-for-a-hat",
      category: "historical",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-11",
      title: "Born a Crime",
      url: "https://example.com/jr-born-a-crime",
      category: "historical",
      author: "Trevor Noah",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/jr-hillbilly-elegy",
      category: "historical",
      author: "J.D. Vance",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-13",
      title: "Quiet",
      url: "https://example.com/jr-quiet",
      category: "historical",
      author: "Susan Cain",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-14",
      title: "The Power of Habit",
      url: "https://example.com/jr-the-power-of-habit",
      category: "historical",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/jr-thinking-fast-and-slow",
      category: "historical",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-16",
      title: "Outliers",
      url: "https://example.com/jr-outliers",
      category: "historical",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-17",
      title: "Freakonomics",
      url: "https://example.com/jr-freakonomics",
      category: "historical",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-18",
      title: "The Tipping Point",
      url: "https://example.com/jr-the-tipping-point",
      category: "historical",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/jr-guns-germs-and-steel",
      category: "historical",
      author: "Jared Diamond",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/jr-the-sixth-extinction",
      category: "historical",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-21",
      title: "Bad Blood",
      url: "https://example.com/jr-bad-blood",
      category: "historical",
      author: "John Carreyrou",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-22",
      title: "The Innovators",
      url: "https://example.com/jr-the-innovators",
      category: "historical",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-23",
      title: "Team of Rivals",
      url: "https://example.com/jr-team-of-rivals",
      category: "historical",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-24",
      title: "Steve Jobs",
      url: "https://example.com/jr-steve-jobs",
      category: "historical",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-25",
      title: "Moneyball",
      url: "https://example.com/jr-moneyball",
      category: "historical",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-26",
      title: "The Big Short",
      url: "https://example.com/jr-the-big-short",
      category: "historical",
      author: "Michael Lewis",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-27",
      title: "Bossypants",
      url: "https://example.com/jr-bossypants",
      category: "historical",
      author: "Tina Fey",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    {
      id: "jr-28",
      title: "Lean In",
      url: "https://example.com/jr-lean-in",
      category: "historical",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-coming-soon-non-fiction.png",
    },
    // Repeat similar pattern for other categories: fiction, mystery, romance, fantasy, sci-fi, historical, biography
    // Fiction
    {
      id: "jr-29",
      title: "The Great Gatsby",
      url: "https://example.com/jr-the-great-gatsby",
      category: "biography",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-30",
      title: "To Kill a Mockingbird",
      url: "https://example.com/jr-to-kill-a-mockingbird",
      category: "biography",
      author: "Harper Lee",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-31",
      title: "1984",
      url: "https://example.com/jr-1984",
      category: "biography",
      author: "George Orwell",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-32",
      title: "Pride and Prejudice",
      url: "https://example.com/jr-pride-and-prejudice",
      category: "biography",
      author: "Jane Austen",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-33",
      title: "Moby-Dick",
      url: "https://example.com/jr-moby-dick",
      category: "biography",
      author: "Herman Melville",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-34",
      title: "War and Peace",
      url: "https://example.com/jr-war-and-peace",
      category: "biography",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-35",
      title: "The Catcher in the Rye",
      url: "https://example.com/jr-the-catcher-in-the-rye",
      category: "biography",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-36",
      title: "The Hobbit",
      url: "https://example.com/jr-the-hobbit",
      category: "biography",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-37",
      title: "Ulysses",
      url: "https://example.com/jr-ulysses",
      category: "biography",
      author: "James Joyce",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-38",
      title: "The Brothers Karamazov",
      url: "https://example.com/jr-the-brothers-karamazov",
      category: "biography",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-39",
      title: "Wuthering Heights",
      url: "https://example.com/jr-wuthering-heights",
      category: "biography",
      author: "Emily Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-40",
      title: "Great Expectations",
      url: "https://example.com/jr-great-expectations",
      category: "biography",
      author: "Charles Dickens",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-41",
      title: "Catch-22",
      url: "https://example.com/jr-catch-22",
      category: "biography",
      author: "Joseph Heller",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-42",
      title: "Crime and Punishment",
      url: "https://example.com/jr-crime-and-punishment",
      category: "biography",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-43",
      title: "Frankenstein",
      url: "https://example.com/jr-frankenstein",
      category: "biography",
      author: "Mary Shelley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-44",
      title: "Jane Eyre",
      url: "https://example.com/jr-jane-eyre",
      category: "biography",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-45",
      title: "Brave New World",
      url: "https://example.com/jr-brave-new-world",
      category: "biography",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-46",
      title: "The Odyssey",
      url: "https://example.com/jr-the-odyssey",
      category: "biography",
      author: "Homer",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-47",
      title: "Les Misérables",
      url: "https://example.com/jr-les-miserables",
      category: "biography",
      author: "Victor Hugo",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-48",
      title: "Don Quixote",
      url: "https://example.com/jr-don-quixote",
      category: "biography",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-49",
      title: "The Grapes of Wrath",
      url: "https://example.com/jr-the-grapes-of-wrath",
      category: "biography",
      author: "John Steinbeck",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-50",
      title: "Dracula",
      url: "https://example.com/jr-dracula",
      category: "biography",
      author: "Bram Stoker",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-51",
      title: "Heart of Darkness",
      url: "https://example.com/jr-heart-of-darkness",
      category: "biography",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-52",
      title: "Anna Karenina",
      url: "https://example.com/jr-anna-karenina",
      category: "biography",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-53",
      title: "Lord of the Flies",
      url: "https://example.com/jr-lord-of-the-flies",
      category: "biography",
      author: "William Golding",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-54",
      title: "Beloved",
      url: "https://example.com/jr-beloved",
      category: "biography",
      author: "Toni Morrison",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-55",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/jr-one-hundred-years-of-solitude",
      category: "biography",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
    {
      id: "jr-56",
      title: "The Count of Monte Cristo",
      url: "https://example.com/jr-the-count-of-monte-cristo",
      category: "biography",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-coming-soon-fiction.png",
    },
  ],
  url: "#",
}

export const USER_SHELVES = {
  id: "user-bookshelf",
  url: "#",
  books: [
    {
      id: "cr-1",
      title: "Sapiens",
      url: "https://example.com/cr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "currently-reading",
    },
    {
      id: "cr-2",
      title: "Educated",
      url: "https://example.com/cr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "currently-reading",
    },
    {
      id: "cr-3",
      title: "Becoming",
      url: "https://example.com/cr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "currently-reading",
    },
    {
      id: "tr-1",
      title: "The Great Gatsby",
      url: "https://example.com/tr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-2",
      title: "To Kill a Mockingbird",
      url: "https://example.com/tr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-3",
      title: "1984",
      url: "https://example.com/tr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-4",
      title: "Pride and Prejudice",
      url: "https://example.com/tr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-5",
      title: "Moby-Dick",
      url: "https://example.com/tr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-6",
      title: "War and Peace",
      url: "https://example.com/tr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-7",
      title: "The Catcher in the Rye",
      url: "https://example.com/tr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-8",
      title: "The Hobbit",
      url: "https://example.com/tr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-9",
      title: "Ulysses",
      url: "https://example.com/tr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-10",
      title: "The Brothers Karamazov",
      url: "https://example.com/tr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-11",
      title: "Wuthering Heights",
      url: "https://example.com/tr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-12",
      title: "Great Expectations",
      url: "https://example.com/tr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-13",
      title: "Catch-22",
      url: "https://example.com/tr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-14",
      title: "Crime and Punishment",
      url: "https://example.com/tr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-15",
      title: "Frankenstein",
      url: "https://example.com/tr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-16",
      title: "Jane Eyre",
      url: "https://example.com/tr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-17",
      title: "Brave New World",
      url: "https://example.com/tr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-18",
      title: "The Odyssey",
      url: "https://example.com/tr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-19",
      title: "Les Misérables",
      url: "https://example.com/tr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-20",
      title: "Don Quixote",
      url: "https://example.com/tr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-21",
      title: "The Grapes of Wrath",
      url: "https://example.com/tr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-22",
      title: "Dracula",
      url: "https://example.com/tr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-23",
      title: "Heart of Darkness",
      url: "https://example.com/tr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-24",
      title: "Anna Karenina",
      url: "https://example.com/tr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-25",
      title: "Lord of the Flies",
      url: "https://example.com/tr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-26",
      title: "Beloved",
      url: "https://example.com/tr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-27",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/tr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "tr-28",
      title: "The Count of Monte Cristo",
      url: "https://example.com/tr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-to-read.png",
      shelf: "to-read",
    },
    {
      id: "cr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/cr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-5",
      title: "The Wright Brothers",
      url: "https://example.com/cr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-6",
      title: "Into the Wild",
      url: "https://example.com/cr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-7",
      title: "The Glass Castle",
      url: "https://example.com/cr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-8",
      title: "Unbroken",
      url: "https://example.com/cr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-9",
      title: "The Devil in the White City",
      url: "https://example.com/cr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/cr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-11",
      title: "Born a Crime",
      url: "https://example.com/cr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/cr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-13",
      title: "Quiet",
      url: "https://example.com/cr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-14",
      title: "The Power of Habit",
      url: "https://example.com/cr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/cr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-16",
      title: "Outliers",
      url: "https://example.com/cr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-17",
      title: "Freakonomics",
      url: "https://example.com/cr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-18",
      title: "The Tipping Point",
      url: "https://example.com/cr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/cr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/cr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-21",
      title: "Bad Blood",
      url: "https://example.com/cr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-22",
      title: "The Innovators",
      url: "https://example.com/cr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-23",
      title: "Team of Rivals",
      url: "https://example.com/cr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-24",
      title: "Steve Jobs",
      url: "https://example.com/cr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-25",
      title: "Moneyball",
      url: "https://example.com/cr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-26",
      title: "The Big Short",
      url: "https://example.com/cr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-27",
      title: "Bossypants",
      url: "https://example.com/cr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-28",
      title: "Lean In",
      url: "https://example.com/cr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-1",
      title: "Sapiens",
      url: "https://example.com/cr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-2",
      title: "Educated",
      url: "https://example.com/cr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-3",
      title: "Becoming",
      url: "https://example.com/cr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/cr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-5",
      title: "The Wright Brothers",
      url: "https://example.com/cr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-6",
      title: "Into the Wild",
      url: "https://example.com/cr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-7",
      title: "The Glass Castle",
      url: "https://example.com/cr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-8",
      title: "Unbroken",
      url: "https://example.com/cr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-9",
      title: "The Devil in the White City",
      url: "https://example.com/cr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/cr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-11",
      title: "Born a Crime",
      url: "https://example.com/cr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/cr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-13",
      title: "Quiet",
      url: "https://example.com/cr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-14",
      title: "The Power of Habit",
      url: "https://example.com/cr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/cr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-16",
      title: "Outliers",
      url: "https://example.com/cr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-17",
      title: "Freakonomics",
      url: "https://example.com/cr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-18",
      title: "The Tipping Point",
      url: "https://example.com/cr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/cr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/cr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-21",
      title: "Bad Blood",
      url: "https://example.com/cr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-22",
      title: "The Innovators",
      url: "https://example.com/cr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-23",
      title: "Team of Rivals",
      url: "https://example.com/cr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-24",
      title: "Steve Jobs",
      url: "https://example.com/cr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-25",
      title: "Moneyball",
      url: "https://example.com/cr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-26",
      title: "The Big Short",
      url: "https://example.com/cr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-27",
      title: "Bossypants",
      url: "https://example.com/cr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "cr-28",
      title: "Lean In",
      url: "https://example.com/cr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "must-read",
    },
    {
      id: "tr-1",
      title: "The Great Gatsby",
      url: "https://example.com/tr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-2",
      title: "To Kill a Mockingbird",
      url: "https://example.com/tr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-3",
      title: "1984",
      url: "https://example.com/tr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-4",
      title: "Pride and Prejudice",
      url: "https://example.com/tr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-5",
      title: "Moby-Dick",
      url: "https://example.com/tr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-6",
      title: "War and Peace",
      url: "https://example.com/tr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-7",
      title: "The Catcher in the Rye",
      url: "https://example.com/tr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-8",
      title: "The Hobbit",
      url: "https://example.com/tr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-9",
      title: "Ulysses",
      url: "https://example.com/tr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-10",
      title: "The Brothers Karamazov",
      url: "https://example.com/tr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-11",
      title: "Wuthering Heights",
      url: "https://example.com/tr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-12",
      title: "Great Expectations",
      url: "https://example.com/tr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-13",
      title: "Catch-22",
      url: "https://example.com/tr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-14",
      title: "Crime and Punishment",
      url: "https://example.com/tr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-15",
      title: "Frankenstein",
      url: "https://example.com/tr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-16",
      title: "Jane Eyre",
      url: "https://example.com/tr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-17",
      title: "Brave New World",
      url: "https://example.com/tr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-18",
      title: "The Odyssey",
      url: "https://example.com/tr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-19",
      title: "Les Misérables",
      url: "https://example.com/tr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-20",
      title: "Don Quixote",
      url: "https://example.com/tr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-21",
      title: "The Grapes of Wrath",
      url: "https://example.com/tr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-22",
      title: "Dracula",
      url: "https://example.com/tr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-23",
      title: "Heart of Darkness",
      url: "https://example.com/tr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-24",
      title: "Anna Karenina",
      url: "https://example.com/tr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-25",
      title: "Lord of the Flies",
      url: "https://example.com/tr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-26",
      title: "Beloved",
      url: "https://example.com/tr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-27",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/tr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "tr-28",
      title: "The Count of Monte Cristo",
      url: "https://example.com/tr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-to-read.png",
      shelf: "read",
    },
    {
      id: "cr-1",
      title: "Sapiens",
      url: "https://example.com/cr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-2",
      title: "Educated",
      url: "https://example.com/cr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-3",
      title: "Becoming",
      url: "https://example.com/cr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/cr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-5",
      title: "The Wright Brothers",
      url: "https://example.com/cr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-6",
      title: "Into the Wild",
      url: "https://example.com/cr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-7",
      title: "The Glass Castle",
      url: "https://example.com/cr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-8",
      title: "Unbroken",
      url: "https://example.com/cr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-9",
      title: "The Devil in the White City",
      url: "https://example.com/cr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/cr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-11",
      title: "Born a Crime",
      url: "https://example.com/cr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/cr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-13",
      title: "Quiet",
      url: "https://example.com/cr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-14",
      title: "The Power of Habit",
      url: "https://example.com/cr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/cr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-16",
      title: "Outliers",
      url: "https://example.com/cr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-17",
      title: "Freakonomics",
      url: "https://example.com/cr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-18",
      title: "The Tipping Point",
      url: "https://example.com/cr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/cr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/cr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-21",
      title: "Bad Blood",
      url: "https://example.com/cr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-22",
      title: "The Innovators",
      url: "https://example.com/cr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-23",
      title: "Team of Rivals",
      url: "https://example.com/cr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-24",
      title: "Steve Jobs",
      url: "https://example.com/cr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-25",
      title: "Moneyball",
      url: "https://example.com/cr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-26",
      title: "The Big Short",
      url: "https://example.com/cr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-27",
      title: "Bossypants",
      url: "https://example.com/cr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "cr-28",
      title: "Lean In",
      url: "https://example.com/cr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "on-hold",
    },
    {
      id: "tr-1",
      title: "The Great Gatsby",
      url: "https://example.com/tr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-2",
      title: "To Kill a Mockingbird",
      url: "https://example.com/tr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-3",
      title: "1984",
      url: "https://example.com/tr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-4",
      title: "Pride and Prejudice",
      url: "https://example.com/tr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-5",
      title: "Moby-Dick",
      url: "https://example.com/tr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-6",
      title: "War and Peace",
      url: "https://example.com/tr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-7",
      title: "The Catcher in the Rye",
      url: "https://example.com/tr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-8",
      title: "The Hobbit",
      url: "https://example.com/tr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-9",
      title: "Ulysses",
      url: "https://example.com/tr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-10",
      title: "The Brothers Karamazov",
      url: "https://example.com/tr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-11",
      title: "Wuthering Heights",
      url: "https://example.com/tr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-12",
      title: "Great Expectations",
      url: "https://example.com/tr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-13",
      title: "Catch-22",
      url: "https://example.com/tr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-14",
      title: "Crime and Punishment",
      url: "https://example.com/tr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-15",
      title: "Frankenstein",
      url: "https://example.com/tr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-16",
      title: "Jane Eyre",
      url: "https://example.com/tr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-17",
      title: "Brave New World",
      url: "https://example.com/tr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-18",
      title: "The Odyssey",
      url: "https://example.com/tr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-19",
      title: "Les Misérables",
      url: "https://example.com/tr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-20",
      title: "Don Quixote",
      url: "https://example.com/tr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-21",
      title: "The Grapes of Wrath",
      url: "https://example.com/tr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-22",
      title: "Dracula",
      url: "https://example.com/tr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-23",
      title: "Heart of Darkness",
      url: "https://example.com/tr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-24",
      title: "Anna Karenina",
      url: "https://example.com/tr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-25",
      title: "Lord of the Flies",
      url: "https://example.com/tr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-26",
      title: "Beloved",
      url: "https://example.com/tr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-27",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/tr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "tr-28",
      title: "The Count of Monte Cristo",
      url: "https://example.com/tr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-to-read.png",
      shelf: "did-not-finish",
    },
    {
      id: "cr-1",
      title: "Sapiens",
      url: "https://example.com/cr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-2",
      title: "Educated",
      url: "https://example.com/cr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-3",
      title: "Becoming",
      url: "https://example.com/cr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/cr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-5",
      title: "The Wright Brothers",
      url: "https://example.com/cr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-6",
      title: "Into the Wild",
      url: "https://example.com/cr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-7",
      title: "The Glass Castle",
      url: "https://example.com/cr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-8",
      title: "Unbroken",
      url: "https://example.com/cr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-9",
      title: "The Devil in the White City",
      url: "https://example.com/cr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/cr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-11",
      title: "Born a Crime",
      url: "https://example.com/cr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/cr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-13",
      title: "Quiet",
      url: "https://example.com/cr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-14",
      title: "The Power of Habit",
      url: "https://example.com/cr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/cr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-16",
      title: "Outliers",
      url: "https://example.com/cr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-17",
      title: "Freakonomics",
      url: "https://example.com/cr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-18",
      title: "The Tipping Point",
      url: "https://example.com/cr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/cr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/cr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-21",
      title: "Bad Blood",
      url: "https://example.com/cr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-22",
      title: "The Innovators",
      url: "https://example.com/cr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-23",
      title: "Team of Rivals",
      url: "https://example.com/cr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-24",
      title: "Steve Jobs",
      url: "https://example.com/cr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-25",
      title: "Moneyball",
      url: "https://example.com/cr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-26",
      title: "The Big Short",
      url: "https://example.com/cr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-27",
      title: "Bossypants",
      url: "https://example.com/cr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "cr-28",
      title: "Lean In",
      url: "https://example.com/cr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover-currently-reading.png",
      shelf: "to-read",
    },
    {
      id: "tr-1",
      title: "The Great Gatsby",
      url: "https://example.com/tr-the-great-gatsby",
      category: "fiction",
      author: "F. Scott Fitzgerald",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-2",
      title: "To Kill a Mockingbird",
      url: "https://example.com/tr-to-kill-a-mockingbird",
      category: "fiction",
      author: "Harper Lee",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-3",
      title: "1984",
      url: "https://example.com/tr-1984",
      category: "fiction",
      author: "George Orwell",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-4",
      title: "Pride and Prejudice",
      url: "https://example.com/tr-pride-and-prejudice",
      category: "fiction",
      author: "Jane Austen",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-5",
      title: "Moby-Dick",
      url: "https://example.com/tr-moby-dick",
      category: "fiction",
      author: "Herman Melville",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-6",
      title: "War and Peace",
      url: "https://example.com/tr-war-and-peace",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-7",
      title: "The Catcher in the Rye",
      url: "https://example.com/tr-the-catcher-in-the-rye",
      category: "fiction",
      author: "J.D. Salinger",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-8",
      title: "The Hobbit",
      url: "https://example.com/tr-the-hobbit",
      category: "fiction",
      author: "J.R.R. Tolkien",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-9",
      title: "Ulysses",
      url: "https://example.com/tr-ulysses",
      category: "fiction",
      author: "James Joyce",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-10",
      title: "The Brothers Karamazov",
      url: "https://example.com/tr-the-brothers-karamazov",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-11",
      title: "Wuthering Heights",
      url: "https://example.com/tr-wuthering-heights",
      category: "fiction",
      author: "Emily Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-12",
      title: "Great Expectations",
      url: "https://example.com/tr-great-expectations",
      category: "fiction",
      author: "Charles Dickens",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-13",
      title: "Catch-22",
      url: "https://example.com/tr-catch-22",
      category: "fiction",
      author: "Joseph Heller",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-14",
      title: "Crime and Punishment",
      url: "https://example.com/tr-crime-and-punishment",
      category: "fiction",
      author: "Fyodor Dostoevsky",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-15",
      title: "Frankenstein",
      url: "https://example.com/tr-frankenstein",
      category: "fiction",
      author: "Mary Shelley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-16",
      title: "Jane Eyre",
      url: "https://example.com/tr-jane-eyre",
      category: "fiction",
      author: "Charlotte Brontë",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-17",
      title: "Brave New World",
      url: "https://example.com/tr-brave-new-world",
      category: "fiction",
      author: "Aldous Huxley",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-18",
      title: "The Odyssey",
      url: "https://example.com/tr-the-odyssey",
      category: "fiction",
      author: "Homer",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-19",
      title: "Les Misérables",
      url: "https://example.com/tr-les-miserables",
      category: "fiction",
      author: "Victor Hugo",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-20",
      title: "Don Quixote",
      url: "https://example.com/tr-don-quixote",
      category: "fiction",
      author: "Miguel de Cervantes",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-21",
      title: "The Grapes of Wrath",
      url: "https://example.com/tr-the-grapes-of-wrath",
      category: "fiction",
      author: "John Steinbeck",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-22",
      title: "Dracula",
      url: "https://example.com/tr-dracula",
      category: "fiction",
      author: "Bram Stoker",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-23",
      title: "Heart of Darkness",
      url: "https://example.com/tr-heart-of-darkness",
      category: "fiction",
      author: "Joseph Conrad",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-24",
      title: "Anna Karenina",
      url: "https://example.com/tr-anna-karenina",
      category: "fiction",
      author: "Leo Tolstoy",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-25",
      title: "Lord of the Flies",
      url: "https://example.com/tr-lord-of-the-flies",
      category: "fiction",
      author: "William Golding",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-26",
      title: "Beloved",
      url: "https://example.com/tr-beloved",
      category: "fiction",
      author: "Toni Morrison",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-27",
      title: "One Hundred Years of Solitude",
      url: "https://example.com/tr-one-hundred-years-of-solitude",
      category: "fiction",
      author: "Gabriel Garcia Marquez",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
    {
      id: "tr-28",
      title: "The Count of Monte Cristo",
      url: "https://example.com/tr-the-count-of-monte-cristo",
      category: "fiction",
      author: "Alexandre Dumas",
      cover: "/assets/book-cover-to-read.png",
      shelf: "must-read",
    },
  ],
}

export const TOP_RATED = {
  books: [
    {
      id: "cr-1",
      title: "Sapiens",
      url: "https://example.com/cr-sapiens",
      category: "non-fiction",
      author: "Yuval Noah Harari",
      cover: "/assets/book-cover.png",
      shelf: "currently-reading",
    },
    {
      id: "cr-2",
      title: "Educated",
      url: "https://example.com/cr-educated",
      category: "non-fiction",
      author: "Tara Westover",
      cover: "/assets/book-cover.png",
      shelf: "currently-reading",
    },
    {
      id: "cr-3",
      title: "Becoming",
      url: "https://example.com/cr-becoming",
      category: "non-fiction",
      author: "Michelle Obama",
      cover: "/assets/book-cover.png",
      shelf: "currently-reading",
    },
    {
      id: "cr-4",
      title: "The Immortal Life of Henrietta Lacks",
      url: "https://example.com/cr-the-immortal-life-of-henrietta-lacks",
      category: "non-fiction",
      author: "Rebecca Skloot",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-5",
      title: "The Wright Brothers",
      url: "https://example.com/cr-the-wright-brothers",
      category: "non-fiction",
      author: "David McCullough",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-6",
      title: "Into the Wild",
      url: "https://example.com/cr-into-the-wild",
      category: "non-fiction",
      author: "Jon Krakauer",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-7",
      title: "The Glass Castle",
      url: "https://example.com/cr-the-glass-castle",
      category: "non-fiction",
      author: "Jeannette Walls",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-8",
      title: "Unbroken",
      url: "https://example.com/cr-unbroken",
      category: "non-fiction",
      author: "Laura Hillenbrand",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-9",
      title: "The Devil in the White City",
      url: "https://example.com/cr-the-devil-in-the-white-city",
      category: "non-fiction",
      author: "Erik Larson",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-10",
      title: "The Man Who Mistook His Wife for a Hat",
      url: "https://example.com/cr-the-man-who-mistook-his-wife-for-a-hat",
      category: "non-fiction",
      author: "Oliver Sacks",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-11",
      title: "Born a Crime",
      url: "https://example.com/cr-born-a-crime",
      category: "non-fiction",
      author: "Trevor Noah",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-12",
      title: "Hillbilly Elegy",
      url: "https://example.com/cr-hillbilly-elegy",
      category: "non-fiction",
      author: "J.D. Vance",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-13",
      title: "Quiet",
      url: "https://example.com/cr-quiet",
      category: "non-fiction",
      author: "Susan Cain",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-14",
      title: "The Power of Habit",
      url: "https://example.com/cr-the-power-of-habit",
      category: "non-fiction",
      author: "Charles Duhigg",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-15",
      title: "Thinking, Fast and Slow",
      url: "https://example.com/cr-thinking-fast-and-slow",
      category: "non-fiction",
      author: "Daniel Kahneman",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-16",
      title: "Outliers",
      url: "https://example.com/cr-outliers",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-17",
      title: "Freakonomics",
      url: "https://example.com/cr-freakonomics",
      category: "non-fiction",
      author: "Steven D. Levitt",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-18",
      title: "The Tipping Point",
      url: "https://example.com/cr-the-tipping-point",
      category: "non-fiction",
      author: "Malcolm Gladwell",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-19",
      title: "Guns, Germs, and Steel",
      url: "https://example.com/cr-guns-germs-and-steel",
      category: "non-fiction",
      author: "Jared Diamond",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-20",
      title: "The Sixth Extinction",
      url: "https://example.com/cr-the-sixth-extinction",
      category: "non-fiction",
      author: "Elizabeth Kolbert",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-21",
      title: "Bad Blood",
      url: "https://example.com/cr-bad-blood",
      category: "non-fiction",
      author: "John Carreyrou",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-22",
      title: "The Innovators",
      url: "https://example.com/cr-the-innovators",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-23",
      title: "Team of Rivals",
      url: "https://example.com/cr-team-of-rivals",
      category: "non-fiction",
      author: "Doris Kearns Goodwin",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-24",
      title: "Steve Jobs",
      url: "https://example.com/cr-steve-jobs",
      category: "non-fiction",
      author: "Walter Isaacson",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-25",
      title: "Moneyball",
      url: "https://example.com/cr-moneyball",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-26",
      title: "The Big Short",
      url: "https://example.com/cr-the-big-short",
      category: "non-fiction",
      author: "Michael Lewis",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-27",
      title: "Bossypants",
      url: "https://example.com/cr-bossypants",
      category: "non-fiction",
      author: "Tina Fey",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
    {
      id: "cr-28",
      title: "Lean In",
      url: "https://example.com/cr-lean-in",
      category: "non-fiction",
      author: "Sheryl Sandberg",
      cover: "/assets/book-cover.png",
      shelf: "must-read",
    },
  ],
  url: "#",
}

export const BOOK_LISTS = [
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/list-book-cover.jpg" },
      { title: "Book 2", cover: "/assets/book-cover-currently-reading.png" },
      {
        title: "Book 3",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 4", cover: "/assets/book-cover-month.png" },
      { title: "Book 5", cover: "/assets/book-cover-to-read.png" },
    ],
    authorName: "Author 1",
    likes: 123456,
    totalComments: 456,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 2", cover: "/assets/book-cover-month.png" },
      {
        title: "Book 3",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 4", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 5", cover: "/assets/list-book-cover.jpg" },
    ],
    authorName: "Author 2",
    likes: 145678,
    totalComments: 389,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-month.png" },
      { title: "Book 2", cover: "/assets/list-book-cover.jpg" },
      { title: "Book 3", cover: "/assets/book-cover-to-read.png" },
      {
        title: "Book 4",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 5", cover: "/assets/book-cover-currently-reading.png" },
    ],
    authorName: "Author 3",
    likes: 167890,
    totalComments: 512,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      {
        title: "Book 1",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 2", cover: "/assets/book-cover-month.png" },
      { title: "Book 3", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 4", cover: "/assets/list-book-cover.jpg" },
      { title: "Book 5", cover: "/assets/book-cover-to-read.png" },
    ],
    authorName: "Author 4",
    likes: 189234,
    totalComments: 678,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 2", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 3", cover: "/assets/book-cover-month.png" },
      {
        title: "Book 4",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 5",
    likes: 213456,
    totalComments: 789,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      {
        title: "Book 1",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 2", cover: "/assets/book-cover.png" },
      { title: "Book 3", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 4", cover: "/assets/book-cover-month.png" },
      { title: "Book 5", cover: "/assets/book-cover-to-read.png" },
    ],
    authorName: "Author 6",
    likes: 234567,
    totalComments: 456,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 2", cover: "/assets/book-cover-currently-reading.png" },
      {
        title: "Book 3",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 4", cover: "/assets/book-cover-month.png" },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 7",
    likes: 256789,
    totalComments: 567,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-month.png" },
      {
        title: "Book 2",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 3", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 4", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 8",
    likes: 278901,
    totalComments: 689,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      {
        title: "Book 1",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 2", cover: "/assets/book-cover-month.png" },
      { title: "Book 3", cover: "/assets/book-cover.png" },
      { title: "Book 4", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 5", cover: "/assets/book-cover-currently-reading.png" },
    ],
    authorName: "Author 9",
    likes: 289123,
    totalComments: 734,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-to-read.png" },
      {
        title: "Book 2",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 3", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 4", cover: "/assets/book-cover-month.png" },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 10",
    likes: 312345,
    totalComments: 856,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 2", cover: "/assets/book-cover-month.png" },
      { title: "Book 3", cover: "/assets/book-cover-to-read.png" },
      {
        title: "Book 4",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 11",
    likes: 334567,
    totalComments: 945,
  },
  {
    title: "Top 100 Picks: Must Read Books Ever",
    url: "#",
    books: [
      { title: "Book 1", cover: "/assets/book-cover-month.png" },
      {
        title: "Book 2",
        cover: "/assets/book-cover-just-released-fiction.png",
      },
      { title: "Book 3", cover: "/assets/book-cover-currently-reading.png" },
      { title: "Book 4", cover: "/assets/book-cover-to-read.png" },
      { title: "Book 5", cover: "/assets/book-cover.png" },
    ],
    authorName: "Author 12",
    likes: 356789,
    totalComments: 1023,
  },
]

export const SORT_OPTIONS = [
  // [
  { label: "Avg rating", value: "avgRating" },
  { label: "No. of Ratings", value: "totalRatings" },
  { label: "My rating", value: "myRating" },
  { label: "Date published", value: "published" },
  { label: "Date read", value: "dateRead" },
  { label: "Date added", value: "dateAdded" },
  // ],
  // [
  //   { label: 'Highest Rated', value: 'highestRated' },
  //   { label: 'Lowest Rated', value: 'lowestRated' },
  // ],
  // [
  //   { label: 'Highest Rated (By you)', value: 'highestRatedByYou' },
  //   { label: 'Lowest Rated (By you)', value: 'lowestRatedByYou' },
  // ],
  // [
  //   { label: 'Highest Rated (By Olivia)', value: 'highestRatedByAuthor' },
  //   { label: 'Lowest Rated (By Olivia)', value: 'lowestRatedByAuthor' },
  // ],
  // [
  //   { label: 'Book Length (Shortest First)', value: 'bookLengthShortestFirst' },
  //   { label: 'Book Length (Longest First)', value: 'bookLengthLongestFirst' },
  // ],
]

export const SHOW_OPTIONS = [
  { label: "Rating", value: "rating", checked: true },
  { label: "Votes", value: "votes", checked: true },
  { label: "Published on", value: "published", checked: true },
  { label: "Language", value: "language", checked: false },
  { label: "Pages", value: "pages", checked: false },
  // { label: 'Duration', value: 'duration', checked: false },
]

export const CHECKBOX_OPTIONS = {
  tags: [
    { label: "Adventure", value: "adventure", checked: false },
    { label: "Anthologies", value: "anthologies", checked: false },
    { label: "Anthology", value: "anthology", checked: false },
    { label: "Art", value: "art", checked: false },
    { label: "Autobiography", value: "autobiography", checked: false },
    { label: "Biography", value: "biography", checked: false },
    { label: "Children's", value: "childrens", checked: false },
    { label: "Classic", value: "classic", checked: false },
    { label: "Comic", value: "comic", checked: false },
    { label: "Comics", value: "comics", checked: false },
    { label: "Cookbooks", value: "cookbooks", checked: false },
    { label: "Crime", value: "crime", checked: false },
    { label: "Detective", value: "detective", checked: false },
    { label: "Diaries", value: "diaries", checked: false },
    { label: "Drama", value: "drama", checked: false },
    { label: "Dystopian", value: "dystopian", checked: false },
    { label: "Education", value: "education", checked: false },
    { label: "Essays", value: "essays", checked: false },
    { label: "Fantasy", value: "fantasy", checked: false },
    { label: "Fiction", value: "fiction", checked: false },
    { label: "Graphic Novel", value: "graphic_novel", checked: false },
    { label: "Guide", value: "guide", checked: false },
    { label: "Health", value: "health", checked: false },
    {
      label: "Historical Fiction",
      value: "historical_fiction",
      checked: false,
    },
    { label: "History", value: "history", checked: false },
    { label: "Horror", value: "horror", checked: false },
    { label: "Journals", value: "journals", checked: false },
    { label: "Math", value: "math", checked: false },
    { label: "Memoir", value: "memoir", checked: false },
    { label: "Mystery", value: "mystery", checked: false },
    { label: "Non-fiction", value: "non_fiction", checked: false },
    { label: "Poetry", value: "poetry", checked: false },
    { label: "Prayer books", value: "prayer_books", checked: false },
    { label: "Religion", value: "religion", checked: false },
    { label: "Romance", value: "romance", checked: false },
    { label: "Satire", value: "satire", checked: false },
    { label: "Science", value: "science", checked: false },
    { label: "Science Fiction", value: "science_fiction", checked: false },
    { label: "Self-Help", value: "self_help", checked: false },
    { label: "Series", value: "series", checked: false },
    { label: "Short Stories", value: "short_stories", checked: false },
    { label: "Suspense", value: "suspense", checked: false },
    { label: "Thriller", value: "thriller", checked: false },
    { label: "Travel", value: "travel", checked: false },
    { label: "True Crime", value: "true_crime", checked: false },
    { label: "Western", value: "western", checked: false },
    { label: "Young Adult", value: "young_adult", checked: false },
  ],
  years: [
    { label: "2020s", value: "2020", checked: false },
    { label: "2010s", value: "2010", checked: false },
    { label: "2000s", value: "2000", checked: false },
    { label: "1990s", value: "1990", checked: false },
    { label: "1980s", value: "1980", checked: false },
    { label: "1970s", value: "1970", checked: false },
    { label: "1960s", value: "1960", checked: false },
    { label: "1950s", value: "1950", checked: false },
    { label: "1940s", value: "1940", checked: false },
    { label: "1930s", value: "1930", checked: false },
    { label: "1920s", value: "1920", checked: false },
    { label: "1910s", value: "1910", checked: false },
    { label: "1900s", value: "1900", checked: false },
  ],
}

export const FILTER_OPTIONS = [
  {
    label: "Currently Reading",
    value: "currentlyReading",
  },
  {
    label: "To Read",
    value: "toRead",
  },
  {
    label: "On Hold",
    value: "onHold",
  },

  {
    label: "Did Not Finish",
    value: "didNotFinish",
  },
  { label: "Show Rated Books", value: "showRatedBooks" },
  { label: "Hide Rated Books", value: "hideRatedBooks" },
  { label: "Show Reviewed Books", value: "showReviewedBooks" },
  { label: "Hide Reviewed Books", value: "hideReviewedBooks" },
]

export const TRENDING_LISTS = [
  {
    id: 1,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 2,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 3,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 4,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 5,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 6,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 7,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 8,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 9,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
  {
    id: 10,
    title: "Top 100 Fantasy Books Of The 21st Century Everyone Must Read",
    url: "list/1",
    author: "Miranda Hedge",
    books: JUST_RELEASED.books,
    likes: 246400,
    comments: 896,
    description:
      "A comprehensive guide to the most imaginative, magical, and captivating fantasy books published. A comprehensive guide to the most imaginative, magical, and captivating fantasy books published...",
  },
]

export const mockReviews = [
  {
    id: 1,
    author: "Bookworm99",
    type: "standard",
    published: "1hr ago",
    content: `A Court of Mist and Fury" by Sarah J. Maas is a masterpiece that left me absolutely spellbound. Picking up from the events of "A Court of Thorns and Roses," this book delves deep into Feyre's emotional and psychological journey after her traumatic experiences Under the Mountain. Maas portrays Feyre's struggle with PTSD so realistically that it feels deeply personal and moving.
    \nThe character development is phenomenal, especially with Rhysand, the mysterious High Lord of the Night Court. His interactions with Feyre are filled with tension and unspoken emotions, making their slow-burn romance incredibly captivating. The new characters—Mor, Cassian, and Azriel—bring so much life and depth to the story.`,
    likes: 456,
    rating: 7,
    replies: [
      {
        id: 1,
        author: "SarahJMaas",
        published: "1hr ago",
        content: `Thank you so much for your wonderful review! I'm thrilled to hear that Feyre's journey and the Night Court resonated with you. Writing about her emotional struggles and the romance with Rhysand was deeply personal, and it means so much to know it touched you. Your support and enthusiasm truly inspire me!`,
        likes: 123,
      },
      {
        id: 2,
        author: "StorySeeker45",
        published: "45min ago",
        content: `So happy you loved the book! Maas’s ability to create such a vivid world and complex characters is truly remarkable. Thanks for highlighting the romance and character depth!`,
        likes: 98,
      },
      {
        id: 3,
        author: "Nightreader111",
        published: "30min ago",
        content: `@StorySeeker45 I agree, Maas’s world-building and character complexity are fantastic. The romance and depth really add to the story's richness. Glad we share the same appreciation for the book!`,
        likes: 76,
      },
    ],
  },
  {
    id: 2,
    author: "SandyKidman",
    type: "critique",
    published: "2hrs ago",
    content: `While I enjoyed "A Court of Mist and Fury," I felt that the pacing was uneven at times. Some parts dragged on, and I found myself skimming through sections that felt overly descriptive. That said, the character development and world-building were still strong points. Rhysand continues to be a fascinating character, and I appreciated the deeper look into the Night Court.`,
    likes: 321,
    rating: 6,
    replies: [
      {
        id: 1,
        author: "Bookworm99",
        published: "1hr ago",
        content: `Interesting point about the pacing! I actually enjoyed the detailed descriptions as they made the world more immersive for me.`,
        likes: 64,
      },
      {
        id: 2,
        author: "SarahJMaas",
        published: "1hr ago",
        content: `Thank you for your honest feedback! Pacing can be tricky, and I always strive to find the right balance. I appreciate your thoughts on the character development and world-building, as they are aspects I'm particularly passionate about.`,
        likes: 89,
      },
    ],
  },
  {
    id: 3,
    author: "BookLover",
    type: "funny",
    published: "3hrs ago",
    rating: 8,
    content: `A Court of Mist and Fury: where Feyre’s emotional rollercoaster makes yours look like a kiddie ride, and Rhysand's charm could make even a stone statue swoon. 8/10 for epic drama!`,
    likes: 789,
    replies: [
      {
        id: 1,
        author: "StorySeeker45",
        published: "2hrs ago",
        content: `@BookLover Totally agree! The drama was off the charts, but that's what made it so addictive.`,
        likes: 111,
      },
      {
        id: 2,
        author: "SarahJMaas",
        published: "1hr ago",
        content: `I’m so glad you enjoyed the drama! Writing those intense scenes was an absolute blast.`,
        likes: 140,
      },
    ],
  },
]

export const DUMMY_NOTIFICATIONS = [
  {
    id: 1,
    type: "reply",
    replyContent: `While your enthusiasm for Harry Potter and the Chamber of Secrets is evident, it's important to consider the narrative depth beyond the surface level. Rowling weaves in subtle themes of prejudice, fear of the unknown, and the power of choice, which are crucial to understanding the characters' development and the broader implications of the story. Delving into these aspects can reveal a richer, more nuanced appreciation of the book that goes beyond its magical adventure.`,
    actors: ["Alice"],
    target: {
      type: "review",
      bookTitle: "The Catcher in the Rye",
      reviewId: 101,
    },
    createdAt: "2024-09-09T12:30:00Z",
    read: false,
  },

  {
    id: 2,
    type: "reply",
    actors: ["Bob", "Charlie"],
    target: {
      type: "review",
      bookTitle: "1984",
      reviewId: 102,
    },
    createdAt: "2024-09-09T11:45:00Z",
    read: false,
  },
  {
    id: 261,
    type: "like_list",
    actors: ["Charlie", "Diana", "Eve"],
    target: {
      type: "list",
      listTitle: "Must-Read Classics",
      listId: 502,
      listURL: "/lists/502",
    },
    createdAt: "2024-09-10T13:50:00Z",
    read: false,
  },
  {
    id: 3,
    type: "reply",
    actors: ["David", "Emily", "Frank"],
    target: {
      type: "review",
      bookTitle: "Moby Dick",
      reviewId: 103,
    },
    createdAt: "2024-09-09T10:50:00Z",
    read: false,
  },
  {
    id: 4,
    type: "like",
    actors: ["Grace"],
    target: {
      type: "review",
      bookTitle: "Pride and Prejudice",
      reviewId: 104,
    },
    createdAt: "2024-09-09T09:30:00Z",
    read: true,
  },
  {
    id: 5,
    type: "like",
    actors: ["Harry", "Ivy"],
    target: {
      type: "review",
      bookTitle: "War and Peace ",
      reviewId: 105,
    },
    createdAt: "2024-09-09T08:45:00Z",
    read: false,
  },
  {
    id: 6,
    type: "like",
    actors: ["Jack", "Kara", "Liam"],
    target: {
      type: "review",
      bookTitle: "To Kill a Mockingbird ",
      reviewId: 106,
    },
    createdAt: "2024-09-08T07:30:00Z",
    read: false,
  },
  {
    id: 7,
    type: "like",
    actors: ["Mona", "Nathan", "Oscar", "Paul"],
    target: {
      type: "review",
      bookTitle: "The Lord of the Rings",
      reviewId: 107,
    },
    createdAt: "2024-09-10T18:00:00Z",
    read: true,
  },
  {
    id: 8,
    type: "system",
    message: "Your password has been reset successfully.",
    createdAt: "2024-09-10T15:00:00Z",
    read: true,
  },
  {
    id: 9,
    type: "system",
    message: "Your reading challenge has been saved.",
    createdAt: "2024-09-10T14:20:00Z",
    read: false,
  },
  {
    id: 10,
    type: "reply",
    replyContent: `While your enthusiasm for Harry Potter and the Chamber of Secrets is evident, it's important to consider the narrative depth beyond the surface level. Rowling weaves in subtle themes of prejudice, fear of the unknown, and the power of choice, which are crucial to understanding the characters' development and the broader implications of the story. Delving into these aspects can reveal a richer, more nuanced appreciation of the book that goes beyond its magical adventure.`,
    actors: ["Quincy"],
    target: {
      type: "review",
      bookTitle: "Brave New World",
      reviewId: 108,
    },
    createdAt: "2024-09-10T13:40:00Z",
    read: false,
  },
  {
    id: 11,
    type: "reply",
    actors: ["Rachel", "Sam"],
    target: {
      type: "review",
      bookTitle: "The Great Gatsby",
      reviewId: 109,
    },
    createdAt: "2024-09-10T12:15:00Z",
    read: true,
  },
  {
    id: 12,
    type: "reply",
    actors: ["Tina", "Uma", "Victor"],
    target: {
      type: "review",
      bookTitle: "Anna Karenina",
      reviewId: 110,
    },
    createdAt: "2024-09-10T11:50:00Z",
    read: false,
  },
  {
    id: 13,
    type: "like",
    actors: ["Will"],
    target: {
      type: "review",
      bookTitle: "Jane Eyre",
      reviewId: 111,
    },
    createdAt: "2024-09-10T10:10:00Z",
    read: false,
  },
  {
    id: 14,
    type: "like",
    actors: ["Xander", "Yvonne"],
    target: {
      type: "review",
      bookTitle: "Crime and Punishment",
      reviewId: 112,
    },
    createdAt: "2024-09-09T16:45:00Z",
    read: true,
  },
  {
    id: 15,
    type: "like",
    actors: ["Zara", "Aaron", "Bella"],
    target: {
      type: "review",
      bookTitle: "The Brothers Karamazov",
      reviewId: 113,
    },
    createdAt: "2024-09-09T15:10:00Z",
    read: false,
  },
  {
    id: 16,
    type: "like",
    actors: ["Charlie", "Diana", "Eve", "Frank"],
    target: {
      type: "review",
      bookTitle: "The Odyssey",
      reviewId: 114,
    },
    createdAt: "2024-09-09T14:30:00Z",
    read: true,
  },
  {
    id: 17,
    type: "system",
    message: "Your list 'Top 10 Sci-Fi Books' was saved successfully.",
    createdAt: "2024-09-09T13:20:00Z",
    read: false,
  },
  {
    id: 18,
    type: "reply",
    replyContent: `This is a classic. I loved it.`,
    actors: ["George"],
    target: {
      type: "review",
      bookTitle: "Les Misérables",
      reviewId: 115,
    },
    createdAt: "2024-09-09T12:05:00Z",
    read: true,
  },
  {
    id: 19,
    type: "reply",
    actors: ["Hannah", "Isaac"],
    target: {
      type: "review",
      bookTitle: "Dracula",
      reviewId: 116,
    },
    createdAt: "2024-09-09T11:30:00Z",
    read: true,
  },
  {
    id: 20,
    type: "reply",
    actors: ["Jack", "Kara", "Liam", "Mona"],
    target: {
      type: "review",
      bookTitle: "The Divine Comedy",
      reviewId: 117,
    },
    createdAt: "2024-09-08T10:00:00Z",
    read: false,
  },
  {
    id: 21,
    type: "like",
    actors: ["Nathan"],
    target: {
      type: "review",
      bookTitle: "Frankenstein",
      reviewId: 118,
    },
    createdAt: "2024-09-08T09:45:00Z",
    read: false,
  },
  {
    id: 22,
    type: "like",
    actors: ["Oscar", "Paul"],
    target: {
      type: "review",
      bookTitle: "The Iliad",
      reviewId: 119,
    },
    createdAt: "2024-09-08T08:20:00Z",
    read: false,
  },
  {
    id: 23,
    type: "system",
    message: "Your email preferences have been updated successfully.",
    createdAt: "2024-09-07T20:00:00Z",
    read: true,
  },
  {
    id: 24,
    type: "system",
    message: "Your account details have been updated.",
    createdAt: "2024-09-07T18:15:00Z",
    read: false,
  },

  {
    id: 25,
    type: "like_list",
    actors: ["Alice", "Bob"],
    target: {
      type: "list",
      listTitle: "Top 10 Sci-Fi Books",
      listId: 501,
      listURL: "/lists/501",
    },
    createdAt: "2024-09-10T14:20:00Z",
    read: false,
  },
  {
    id: 26,
    type: "like_list",
    actors: ["Charlie", "Diana", "Eve"],
    target: {
      type: "list",
      listTitle: "Must-Read Classics",
      listId: 502,
      listURL: "/lists/502",
    },
    createdAt: "2024-09-10T13:50:00Z",
    read: false,
  },
]
